














import {Vue, Component, Prop} from 'vue-property-decorator';
import OnlineOrderCoreModel from '@/modules/online-orders/models/OnlineOrderCoreModel';
import Detail from '@/shared/resources/components/details/Detail.vue';

@Component({
  components: {
    Detail,
  },
})
export default class CartSummaryContactData extends Vue {
  /**
   * Props
   */
  @Prop() private order!: OnlineOrderCoreModel;
}
